const easing = "easeInOutSine";
const property = "translateY";

/**
 * We're only using one type of parallax effect. This function abstracts the
 * lengthy react-plx config and sets some sensible defaults.
 */
export default function createParallaxConfig({ distance, ...rest }) {
  return [
    {
      start: "self",
      duration: "200vh",
      easing,
      properties: [
        {
          startValue: 0,
          endValue: -distance,
          property,
        },
      ],
      ...rest,
    },
  ];
}
